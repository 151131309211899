import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f7568b0 = () => interopDefault(import('../pages/addCreditCard.vue' /* webpackChunkName: "pages/addCreditCard" */))
const _5179f60a = () => interopDefault(import('../pages/highlightSection.vue' /* webpackChunkName: "pages/highlightSection" */))
const _7260f649 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _a4cc8e56 = () => interopDefault(import('../pages/informationStation/index.vue' /* webpackChunkName: "pages/informationStation/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _178eea04 = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _8c7f0404 = () => interopDefault(import('../pages/member/accountSetting.vue' /* webpackChunkName: "pages/member/accountSetting" */))
const _77a49c69 = () => interopDefault(import('../pages/member/address.vue' /* webpackChunkName: "pages/member/address" */))
const _d6b73358 = () => interopDefault(import('../pages/member/bonus.vue' /* webpackChunkName: "pages/member/bonus" */))
const _96d04452 = () => interopDefault(import('../pages/member/bonus/index.vue' /* webpackChunkName: "pages/member/bonus/index" */))
const _d8dda62c = () => interopDefault(import('../pages/member/bonus/listEnterprise.vue' /* webpackChunkName: "pages/member/bonus/listEnterprise" */))
const _29d056c6 = () => interopDefault(import('../pages/member/bonus/listGet.vue' /* webpackChunkName: "pages/member/bonus/listGet" */))
const _17ebfff4 = () => interopDefault(import('../pages/member/bonus/listUsed.vue' /* webpackChunkName: "pages/member/bonus/listUsed" */))
const _1ca4ccd1 = () => interopDefault(import('../pages/member/creditCardBinding.vue' /* webpackChunkName: "pages/member/creditCardBinding" */))
const _27679e03 = () => interopDefault(import('../pages/member/order.vue' /* webpackChunkName: "pages/member/order" */))
const _2b12fdd8 = () => interopDefault(import('../pages/member/order/quotationInfo/_idsn.vue' /* webpackChunkName: "pages/member/order/quotationInfo/_idsn" */))
const _6305e4ee = () => interopDefault(import('../pages/member/order/_idsn.vue' /* webpackChunkName: "pages/member/order/_idsn" */))
const _48c58641 = () => interopDefault(import('../pages/member/orderList.vue' /* webpackChunkName: "pages/member/orderList" */))
const _54e6cb6c = () => interopDefault(import('../pages/member/orderList/_id.vue' /* webpackChunkName: "pages/member/orderList/_id" */))
const _42f2b0ad = () => interopDefault(import('../pages/member/receipt.vue' /* webpackChunkName: "pages/member/receipt" */))
const _17a26027 = () => interopDefault(import('../pages/member/ticket.vue' /* webpackChunkName: "pages/member/ticket" */))
const _618c252c = () => interopDefault(import('../pages/member/ticket/index.vue' /* webpackChunkName: "pages/member/ticket/index" */))
const _cb1c8d94 = () => interopDefault(import('../pages/member/ticket/list.vue' /* webpackChunkName: "pages/member/ticket/list" */))
const _3a915da8 = () => interopDefault(import('../pages/memberUpgrade.vue' /* webpackChunkName: "pages/memberUpgrade" */))
const _2e9f220c = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _4436b7ec = () => interopDefault(import('../pages/payment/pluspay/_idHashCodeSn.vue' /* webpackChunkName: "pages/payment/pluspay/_idHashCodeSn" */))
const _92d70826 = () => interopDefault(import('../pages/payment/_idHashCodeSn.vue' /* webpackChunkName: "pages/payment/_idHashCodeSn" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _0f65648b = () => interopDefault(import('../pages/serviceMultiplePackage.vue' /* webpackChunkName: "pages/serviceMultiplePackage" */))
const _8a5c3678 = () => interopDefault(import('../pages/logistics/multiplePackage/print/_idsn.vue' /* webpackChunkName: "pages/logistics/multiplePackage/print/_idsn" */))
const _6f36e655 = () => interopDefault(import('../pages/activity/agentOrder/_idsn.vue' /* webpackChunkName: "pages/activity/agentOrder/_idsn" */))
const _2ae7a982 = () => interopDefault(import('../pages/informationStation/article/_idsn.vue' /* webpackChunkName: "pages/informationStation/article/_idsn" */))
const _65605f34 = () => interopDefault(import('../pages/logistics/multiplePackage/_idsn.vue' /* webpackChunkName: "pages/logistics/multiplePackage/_idsn" */))
const _339d5c29 = () => interopDefault(import('../pages/laundry/_idsn.vue' /* webpackChunkName: "pages/laundry/_idsn" */))
const _b2157f1e = () => interopDefault(import('../pages/laundryNCS/_idsn.vue' /* webpackChunkName: "pages/laundryNCS/_idsn" */))
const _19519f1f = () => interopDefault(import('../pages/logistics/_idsn.vue' /* webpackChunkName: "pages/logistics/_idsn" */))
const _372a197a = () => interopDefault(import('../pages/service/_service.vue' /* webpackChunkName: "pages/service/_service" */))
const _256ee5c2 = () => interopDefault(import('../pages/category/_category/_service.vue' /* webpackChunkName: "pages/category/_category/_service" */))
const _21988062 = () => interopDefault(import('../pages/category/_category/_service/index.vue' /* webpackChunkName: "pages/category/_category/_service/index" */))
const _ec87cd22 = () => interopDefault(import('../pages/category/_category/_service/comment.vue' /* webpackChunkName: "pages/category/_category/_service/comment" */))
const _8625d25e = () => interopDefault(import('../pages/category/_category/_service/notes.vue' /* webpackChunkName: "pages/category/_category/_service/notes" */))
const _59f4154e = () => interopDefault(import('../pages/category/_category/_service/price.vue' /* webpackChunkName: "pages/category/_category/_service/price" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f3cf0ffa = () => interopDefault(import('../pages/index/_id.vue' /* webpackChunkName: "pages/index/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/addCreditCard",
    component: _5f7568b0,
    name: "addCreditCard"
  }, {
    path: "/highlightSection",
    component: _5179f60a,
    name: "highlightSection"
  }, {
    path: "/home",
    component: _7260f649,
    name: "home"
  }, {
    path: "/informationStation",
    component: _a4cc8e56,
    name: "informationStation"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/member",
    component: _178eea04,
    name: "member",
    children: [{
      path: "accountSetting",
      component: _8c7f0404,
      name: "member-accountSetting"
    }, {
      path: "address",
      component: _77a49c69,
      name: "member-address"
    }, {
      path: "bonus",
      component: _d6b73358,
      children: [{
        path: "",
        component: _96d04452,
        name: "member-bonus"
      }, {
        path: "listEnterprise",
        component: _d8dda62c,
        name: "member-bonus-listEnterprise"
      }, {
        path: "listGet",
        component: _29d056c6,
        name: "member-bonus-listGet"
      }, {
        path: "listUsed",
        component: _17ebfff4,
        name: "member-bonus-listUsed"
      }]
    }, {
      path: "creditCardBinding",
      component: _1ca4ccd1,
      name: "member-creditCardBinding"
    }, {
      path: "order",
      component: _27679e03,
      name: "member-order",
      children: [{
        path: "quotationInfo/:idsn?",
        component: _2b12fdd8,
        name: "member-order-quotationInfo-idsn"
      }, {
        path: ":idsn?",
        component: _6305e4ee,
        name: "member-order-idsn"
      }]
    }, {
      path: "orderList",
      component: _48c58641,
      name: "member-orderList",
      children: [{
        path: ":id?",
        component: _54e6cb6c,
        name: "member-orderList-id"
      }]
    }, {
      path: "receipt",
      component: _42f2b0ad,
      name: "member-receipt"
    }, {
      path: "ticket",
      component: _17a26027,
      children: [{
        path: "",
        component: _618c252c,
        name: "member-ticket"
      }, {
        path: "list",
        component: _cb1c8d94,
        name: "member-ticket-list"
      }]
    }]
  }, {
    path: "/memberUpgrade",
    component: _3a915da8,
    name: "memberUpgrade"
  }, {
    path: "/payment",
    component: _2e9f220c,
    name: "payment",
    children: [{
      path: "pluspay/:idHashCodeSn?",
      component: _4436b7ec,
      name: "payment-pluspay-idHashCodeSn"
    }, {
      path: ":idHashCodeSn?",
      component: _92d70826,
      name: "payment-idHashCodeSn"
    }]
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/serviceMultiplePackage",
    component: _0f65648b,
    name: "serviceMultiplePackage"
  }, {
    path: "/logistics/multiplePackage/print/:idsn?",
    component: _8a5c3678,
    name: "logistics-multiplePackage-print-idsn"
  }, {
    path: "/activity/agentOrder/:idsn?",
    component: _6f36e655,
    name: "activity-agentOrder-idsn"
  }, {
    path: "/informationStation/article/:idsn?",
    component: _2ae7a982,
    name: "informationStation-article-idsn"
  }, {
    path: "/logistics/multiplePackage/:idsn?",
    component: _65605f34,
    name: "logistics-multiplePackage-idsn"
  }, {
    path: "/laundry/:idsn?",
    component: _339d5c29,
    name: "laundry-idsn"
  }, {
    path: "/laundryNCS/:idsn?",
    component: _b2157f1e,
    name: "laundryNCS-idsn"
  }, {
    path: "/logistics/:idsn?",
    component: _19519f1f,
    name: "logistics-idsn"
  }, {
    path: "/service/:service?",
    component: _372a197a,
    name: "service-service"
  }, {
    path: "/category/:category?/:service?",
    component: _256ee5c2,
    children: [{
      path: "",
      component: _21988062,
      name: "category-category-service"
    }, {
      path: "comment",
      component: _ec87cd22,
      name: "category-category-service-comment"
    }, {
      path: "notes",
      component: _8625d25e,
      name: "category-category-service-notes"
    }, {
      path: "price",
      component: _59f4154e,
      name: "category-category-service-price"
    }]
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index",
    children: [{
      path: ":id?",
      component: _f3cf0ffa,
      name: "index-id"
    }]
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
